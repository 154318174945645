@use "../scss/functions";
@import "../scss/Variables.scss";
@import "../scss/Toaster.scss";

.app-root {
    height: 100%;
    display: flex;
    flex-direction: column;

    > .content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    > .footer {
        flex-shrink: 0;
    }
}
