@use "../../scss/functions" as fn;
@use "../../scss/Variables.scss" as vars;

.page-header {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    isolation: isolate;

    .page-header__content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 10;

        .page-header__title {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 10px;

            &.--default {
                margin: 40px 0;

                h1 {
                    font-size: fn.pxToRem(32);
                }
            }

            &.--large {
                height: 200px;

                h1 {
                    font-size: fn.pxToRem(48);
                }
            }
        }
    }

    .subject-switch {
        position: relative;
        z-index: 100;
    }

    .switch-btn {
        display: flex;
        align-items: center;
        padding: 6px 16px;
        margin: 0 12px;
        font-weight: 500;
        background-color: var(--block-item1-selected-teacher);
        border: none;
    }

    .page-header__background {
        position: absolute;
        left: 60%;
        top: 50%;
        transform: translate(-50%, -50%);

        z-index: 0;

        img {
            display: block;
            width: 700px;
            height: 200px;
        }
    }

    .page-header__title-wrapper > *:first-child:not(:only-child) {
        margin-bottom: 0;
    }

    h1 {
        font-weight: 500;
        margin-bottom: 0;
        font-size: fn.pxToRem(28);
        color: var(--text-main-default);
    }

    p {
        color: var(--text-main-default);
        font-size: fn.pxToRem(28);
    }

    &.--is-scrolling {
        z-index: -1;
    }
}

@media screen and (max-width: vars.$breakpoint-medium) {
    .page-header {
        background-color: var(--block-header);

        .page-header__content {
            .page-header__title {
                flex-wrap: wrap;

                &.--large {
                    margin: 16px 0;
                }

                h1 {
                    font-size: fn.pxToRem(18);
                    font-weight: 400;
                }
            }
        }

        .page-header__children {
            display: flex;
            width: 100%;

            > * {
                flex-grow: 1;
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: vars.$breakpoint-small) {
    .page-header {
        height: auto;

        .page-header__content {
            flex-direction: column;
            gap: 16px;

            .page-header__title {
                width: 100%;
                flex-wrap: nowrap;

                > button {
                    height: 48px;
                    width: 48px;
                }

                &.--default,
                &.--large {
                    margin: 0;

                    h1 {
                        font-size: 1rem;
                        font-weight: 500;
                    }
                }

                &.--large {
                    height: auto;
                }

                h1 {
                    display: flex;
                    justify-content: space-between;
                    flex-grow: 1;
                }
            }
        }

        .page-header__background {
            display: none;
        }
    }
}
